import React from 'react'
import Form from '../Form'
import './style.scss'
const ContactArea = ({ className }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-5 col-12">
                        <div className="contactInfo">
                            <span></span>
                            <h2>Get In Touch With Us</h2>
                            <h6>Call us at 248-775-1002 or fill out the form.</h6>
                            <p>We are always happy to discuss new matters with potential clients and answer any questions that you may have.</p>
                            <p>Contact us today for a virtual, phone, or in-person consultation.</p>
                        </div>
                    </div>
                    <div className="col-12 col-lg-7">
                        <Form />
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ContactArea