import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
import Form from '../../../components/Form'
// images
import image from '../../../images/contact/1.jpg'

import './style.scss'

const breadcumbMenu = [
    { name: 'Home', route: '/' },
    { name: 'Contact', },
]

const ContactPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb
                className="breadcumbArea"
                title="Contact"
                breadcumbMenu={breadcumbMenu}
                background={image}
            />

            <div className="contactusPageArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="contactUsInfo">
                                <h3>Contact Us</h3>
                                <p>Please feel free to reach out to us to discuss your situation with no obligation.
                                </p>
                                <h4>Address</h4>
                                <span>
                                    <a aria-label='address' target='_blank' rel="noopener noreferrer" href='https://goo.gl/maps/xUVRGEu6D3F9Wgr2A'> 
                                        1651 South Milford Road - Suite 101, Highland, Michigan 48357
                                    </a>
                                </span>
                                <h4>Phone</h4>
                                <span><a href='tel:248-775-1002'>248-775-1002</a></span>
                                <h4>Email</h4>
                                <span><a href='mailto:contact@sanioslegalgroup.com'>contact@sanioslegalgroup.com</a></span>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div className="contactUSForm">
                                <h3>Quick Contact Form</h3>
                                <Form
                                    addressInfo={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterArea />
        </Fragment>
    )
}
export default ContactPage