import React, { Fragment } from 'react';
import HeaderBottom from '../../../components/HeaderBottom';
import HeaderTop from '../../../components/HeaderTop';
import HeroSlider from '../../../components/HeroSlider';
// import Service from '../../../components/Service';
// import About from '../../../components/About';
import ServiceArea from '../../../components/ServiceArea';
// import Portfolio from '../../../components/Portfolio';
import Testmonial from "../../../components/Testmonial";
import ContactArea from '../../../components/ContactArea';
import TeamMember from '../../../components/TeamMember';
// import CounterArea from '../../../components/CounterArea';
// import BlogArea from '../../../components/BlogArea';
// import NewsLetter from '../../../components/Newsletter';
import FooterArea from '../../../components/FooterArea';
// images
// import about from '../../../images/about/2.jpg';
// import signature from '../../../images/about/1.png';

// images
// import portfolio1 from '../../../images/studies/1.jpg';
// import portfolio2 from '../../../images/studies/2.jpg';
// import portfolio3 from '../../../images/studies/3.jpg';
// import portfolio4 from '../../../images/studies/4.jpg';
// import portfolio5 from '../../../images/studies/5.jpg';

// const aboutText = [
//     { text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,' },
//     { text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum' },
// ];

const heroSliders = [
    {
        images: 'slideWrapperOne',
        title: 'Personalized Representation, Dedication, and Commitment',
        subTitle: '',
        text: 'Sanios Legal Group',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperTwo',
        title: 'Personalized Representation, Dedication, and Commitment',
        subTitle: '',
        text: 'Sanios Legal Group',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperThree',
        title: 'Personalized Representation, Dedication, and Commitment',
        subTitle: '',
        text: 'Sanios Legal Group',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperFour',
        title: 'Personalized Representation, Dedication, and Commitment',
        subTitle: '',
        text: 'Sanios Legal Group',
        button: 'Contact us now'
    },
    {
        images: 'slideWrapperFive',
        title: 'Personalized Representation, Dedication, and Commitment',
        subTitle: '',
        text: 'Sanios Legal Group',
        button: 'Contact us now'
    },
];

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Family law is an area of law involving divorce and child custody, or even guardianships.  We guide our clients through some of the most difficult times in their lives by helping them to understand the process and also advocating for them in the strongest way.  By hiring us to protect your assets or the best interest of your children, you are hiring a dedicated attorney who will treat your case like it is his or her own.'
    },
    {
        icon: 'flaticon-house',
        title: 'Civil Litigation',
        content: 'When you cannot seem to resolve a dispute with your landlord or neighbor or the person who sold you a house, give us a call.  When you have been injured by a dog or another person in an accident, give us a call.  There is no dispute we are afraid to handle, and we have experience litigating in both state and federal courts.  We will consult with you about your potential remedies and aim to resolve disputes without court involvement, if possible, but if the courts need to be involved, we will take care of business.'
    },
    {
        icon: 'bank',
        title: 'Bankruptcy',
        content: 'When people are going through difficult times, finding it hard to pay their creditors, we consult with them, free of charge, as to whether bankruptcy might be right for them.  If we determine that bankruptcy is right for them, we explain the bankruptcy process and what type of bankruptcy they should file.  We are here for our clients the whole time to navigate them through a time in their lives that may seem embarrassing or overwhelming, and we make it easier for them in every way.'
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'We help our clients develop and negotiate contracts for the sale or purchase of real estate or  businesses, including the drafting of purchase agreements or land contracts or Operating or Shareholder Agreements.'
    },
    {
        icon: 'flaticon-network',
        title: 'Wills and Trusts',
        content: 'We help our clients plan for life after their lives by helping them navigate through the differences between wills and trusts and what may be right for them.  We make sure that our clients are assured that their wishes are met with the utmost certainty.'
    },
];


// const portfolioItem = [
//     { images: portfolio1, title: 'General Service', subtitle: 'Corporate' },
//     { images: portfolio2, title: 'Personal Issue', subtitle: 'General' },
//     { images: portfolio3, title: 'Business Accounting', subtitle: 'Business' },
//     { images: portfolio4, title: 'Accounting issue', subtitle: 'Criminal' },
//     { images: portfolio5, title: 'Business Accounting', subtitle: 'Family Issue' }
// ];

const HomePageOne = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBottom className="headerBottomArea" />
            </header>
            <HeroSlider 
                sliders={heroSliders}
                className="heroSliderArea" />
            <ServiceArea
                className="ourServiceArea"
                title="How Can We Help You?"
                subTitle="Areas Of Practice"
                services={services}
            />
{/*
            <Portfolio
                className="portfolioArea"
                title="Our Recent Case Studies"
                subTitle="Here Our Best Work"
                portfolioItem={portfolioItem}
            />
*/}
            <Testmonial
                className="testmonialArea"
            />
            <ContactArea
                className="contactArea"
            />
            <TeamMember
                title="Meet Our Team"
                subTitle=" "
                className="teamArea"
                slider={true}
            />
{/*
            <CounterArea
                className="counterArea"
            />

            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            />
*/}
            <FooterArea/>
        </Fragment>
    )
}
export default HomePageOne