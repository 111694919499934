import React from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../../images/logo/logo.png';
import './style.scss';

const HeaderBottom = props => {

    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerBottomMainWrapper">
                    <div className="row">
                        <div className="col-lg-3 col-md-10 col-sm-6 col-8">
                            <div className="logo">
                                <NavLink to="/">
                                    <img src={logo} alt="" />
                                </NavLink>
                            </div>
                        </div>
                        <div className="col-lg-8 responsiveWrapper active">
                            <ul className="mainMenuWrap">
                                <li><NavLink exact to='/'>Home</NavLink></li>
                                <li><NavLink exact to='/about'>About</NavLink></li>
                                <li><NavLink exact to='/practice'>Practice</NavLink></li>
                                <li><NavLink exact to='/contact'>Contact</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderBottom