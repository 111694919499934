import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
// import Service from '../../../components/Service'
import About from '../../../components/About'
// import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import TeamMember from '../../../components/TeamMember'
// import CounterArea from '../../../components/CounterArea'
// import BlogArea from '../../../components/BlogArea'
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
// import about from '../../../images/about/2.jpg'
import breadcumb from '../../../images/breadcumb/2.jpg'
import './style.scss';
import teamMember1 from '../../../images/expert/vasili.jpg';
// import teamMember2 from '../../../images/expert/katrina.png';


const aboutTextV = [
    { text: 'Attorney Vasilios E. Sanios (Vasili) dedicates himself to his client’s cases as though they were his own cases.  He has years of experience in his areas of practice and is ready to fight for you as though your issues were his issues.  Attorney Sanios also brings with him a deep knowledge base in other fields that help him understand even the most complex factual and legal issues, which is a strength that most other attorneys do not have.  He practices in most of the surrounding counties, including Oakland, Genesee, Livingston, and Washtenaw.'
    },
    { text: 'Mr. Sanios is licensed to practice law in Pennsylvania and Michigan and is admitted into several federal courts including the Western District of Pennsylvania and the Eastern District of Michigan' },
]

// const aboutTextK = [
//     { text: 'Katrina has worked as a Paralegal for 8 years. She has been a notary in the State of Michigan since 2004. In March of 2012, she graduated Highest Honors with an Associate’s Degree in Applied Science/Paralegal Studies. She enjoys spending time with her children and grandchildren.'
//     }
// ]

// const services = [
//     {
//         icon: 'flaticon-parents',
//         title: 'Family Law',
//         content: 'Family law is an area of law involving divorce and child custody, or even guardianships.  We guide our clients through some of the most difficult times in their lives by helping them to understand the process and also advocating for them in the strongest way.  By hiring us to protect your assets or the best interest of your children, you are hiring a dedicated attorney who will treat your case like it is his or her own.'
//     },
//     {
//         icon: 'flaticon-university-graduate-hat',
//         title: 'Civil Litigation',
//         content: 'When you cannot seem to resolve a dispute with your landlord or neighbor or the person who sold you a house, give us a call.  When you have been injured by a dog or another person in an accident, give us a call.  There is no dispute we are afraid to handle, and we have experience litigating in both state and federal courts.  We will consult with you about your potential remedies and aim to resolve disputes without court involvement, if possible, but if the courts need to be involved, we will take care of business.'
//     },
//     {
//         icon: 'flaticon-thief',
//         title: 'Bankruptcy',
//         content: 'When people are going through difficult times, finding it hard to pay their creditors, we consult with them, free of charge, as to whether bankruptcy might be right for them.  If we determine that bankruptcy is right for them, we explain the bankruptcy process and what type of bankruptcy they should file.  We are here for our clients the whole time to navigate them through a time in their lives that may seem embarrassing or overwhelming, and we make it easier for them in every way.'
//     },
//     {
//         icon: 'flaticon-employee',
//         title: 'Business Law',
//         content: 'We help our clients develop and negotiate contracts for the sale or purchase of real estate or  businesses, including the drafting of purchase agreements or land contracts or Operating or Shareholder Agreements.'
//     },
//     {
//         icon: 'flaticon-house',
//         title: 'Wills and Trusts',
//         content: 'We help our clients plan for life after their lives by helping them navigate through the differences between wills and trusts and what may be right for them.  We make sure that our clients are assured that their wishes are met with the utmost certainty.'
//     },
// ];

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'About us'}
]

const AboutPage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="About Us"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Vasili Sanios"
                subTitle=""
                image={teamMember1}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutTextV}
            />
{/*
            <About
                className="aboutArea aboutAreaStyleTwo"
                title="Katrina Hopper"
                subTitle=""
                image={teamMember2}
                orderLast="order-last"
                videoId="XxVg_s8xAms"
                pragraphs={aboutTextK}
                reverse
            />

            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleTwo"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
*/}
            <Testmonial
                className="testmonialArea pt100"
            />
{/*
            <CounterArea
                fullWidth={true}
                className="counterArea counterAreaStyleTwo"
            />

            <TeamMember
                title="Team Members"
                subTitle="Meet Our Experts"
                className="teamArea"
                slider={true}
            />
            <BlogArea
                className="blogArea"
                title="Latest News"
                subTitle="From Our Blog
                "
            />
            <NewsLetter
                className="newsLetterArea"
            />
*/}
            <FooterArea/>
        </Fragment>
    )
}
export default AboutPage