import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/logo/logo.png';
import './style.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

const quickLinks = [
    {
        title: 'Quick Links', menus: [
            { name: 'Home', route: '/' },
            { name: 'Practice', route: '/practice' },
            { name: 'About', route: '/about' },
            { name: 'Contact', route: '/contact' },
        ]
    },
];

const FooterArea = () => {
    return (
        <footer className="footerArea">
            <div className="footerTopArea">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerLogo">
                                <Link to="/">
                                    <img src={logo} alt="" />
                                </Link>
                                <p>Personalized representation, dedication, and commitment.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-0 col-sm-0"/>
                        {quickLinks.map((menu, i) => (
                            <div key={i} className="col-lg-3 col-md-6 col-sm-6">
                                <div className="footerWrap">
                                    <h3>{menu.title}</h3>
                                    <ul>
                                        {menu.menus.map((item, i) => (
                                            <li key={i}>{item.route ? <Link to={`${item.route}`}>{item.name}</Link> : `${item.name}`}</li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div className="col-lg-3 col-md-6 col-sm-6">
                            <div className="footerWrap">
                                <h3>Contact Us</h3>
                                <ul>
                                    <li>Office Address</li>
                                    <li><a aria-label='address' target='_blank' rel="noopener noreferrer" href='https://goo.gl/maps/xUVRGEu6D3F9Wgr2A'> 1651 South Milford Road<br />Suite 101<br />Highland, Michigan 48357</a></li>
                                    <li><a href='tel:2487751002'>248-775-1002</a></li>
                                    <li><a href='mailto:contact@sanioslegalgroup.com'>contact@sanioslegalgroup.com</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footerBottomArea">
                <div className="container">
                    <div className="footerBottomContent">
                        <div className="row">
                            <div className="col-md-8 col-sm-10 col-12">
                                <span>© 2020 Sanios Legal Group, PLLC. All rights reserved</span>
                            </div>
                            <div className="col-md-4 col-sm-2 col-12">
                                <ul className="socialListFooter">
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/sanioslegalgroup"><i className="fa fa-facebook" aria-label='facebook'></i></a></li>
                                    <li><a className='youtube-link' target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/channel/UC4hnqNWTGNlq1xx4jEqLPUg"><FontAwesomeIcon icon={faYoutube} style={{ height: '1.4rem', width: '1.4rem' }} /></a></li>
{/*
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://twitter.com/"><i className="fa fa-twitter" aria-label='twitter'></i></a></li>
                                    <li><a target='_blank' rel="noopener noreferrer" href="https://www.linkedin.com/"><i className="fa fa-linkedin" aria-label='linkedin'></i></a></li>
*/}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default FooterArea