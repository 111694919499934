import React, { Fragment } from 'react'
import HeaderBotton from '../../../components/HeaderBottom'
import HeaderTop from '../../../components/HeaderTop'
import Breadcumb from '../../../components/Breadcumb'
// import Service from '../../../components/Service'
import ServiceArea from '../../../components/ServiceArea'
import Testmonial from "../../../components/Testmonial";
// import PricingTable from "../../../components/PricingTable";
// import NewsLetter from '../../../components/Newsletter'
import FooterArea from '../../../components/FooterArea'
// images
import breadcumb from '../../../images/breadcumb/1.jpg'

import './style.scss'

// const aboutText = [
//     { text: 'Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC, making it over 2000 years old. Richard McClintock, a Latin professor at ,' },
//     { text: 'and going through the cites of the word in classical literature, discovered the undoubtable source. Lorem Ipsum comes from sections 1.10.32 and 1.10.33 of "de Finibus Bonorum et Malorum' },
// ]

const services = [
    {
        icon: 'flaticon-parents',
        title: 'Family Law',
        content: 'Family law is an area of law involving divorce and child custody, or even guardianships.  We guide our clients through some of the most difficult times in their lives by helping them to understand the process and also advocating for them in the strongest way.  By hiring us to protect your assets or the best interest of your children, you are hiring a dedicated attorney who will treat your case like it is his or her own.'
    },
    {
        icon: 'flaticon-house',
        title: 'Civil Litigation',
        content: 'When you cannot seem to resolve a dispute with your landlord or neighbor or the person who sold you a house, give us a call.  When you have been injured by a dog or another person in an accident, give us a call.  There is no dispute we are afraid to handle, and we have experience litigating in both state and federal courts.  We will consult with you about your potential remedies and aim to resolve disputes without court involvement, if possible, but if the courts need to be involved, we will take care of business.'
    },
    {
        icon: 'bank',
        title: 'Bankruptcy',
        content: 'When people are going through difficult times, finding it hard to pay their creditors, we consult with them, free of charge, as to whether bankruptcy might be right for them.  If we determine that bankruptcy is right for them, we explain the bankruptcy process and what type of bankruptcy they should file.  We are here for our clients the whole time to navigate them through a time in their lives that may seem embarrassing or overwhelming, and we make it easier for them in every way.'
    },
    {
        icon: 'flaticon-employee',
        title: 'Business Law',
        content: 'We help our clients develop and negotiate contracts for the sale or purchase of real estate or  businesses, including the drafting of purchase agreements or land contracts or Operating or Shareholder Agreements.'
    },
    {
        icon: 'flaticon-network',
        title: 'Wills and Trusts',
        content: 'We help our clients plan for life after their lives by helping them navigate through the differences between wills and trusts and what may be right for them.  We make sure that our clients are assured that their wishes are met with the utmost certainty.'
    },
];

const breadcumbMenu = [
    {name:'Home',route:'/'},
    {name:'Practice area'}
]

const PracticePage = () => {
    return (
        <Fragment>
            <header className="headerArea">
                <HeaderTop className="headerTop" />
                <HeaderBotton className="headerBottomArea headerBottomAreaStyelTwo" />
            </header>
            <Breadcumb 
                className="breadcumbArea"
                title="Practice Area"
                breadcumbMenu={breadcumbMenu}
                background={breadcumb}
            />
            <ServiceArea
                className="ourServiceArea ourServiceAreaStyleThree bgFFF"
                title="How Can We Help You"
                subTitle="Area Of Practice"
                services={services}
            />
{/*
            <PricingTable 
                className="pricingTableArea"
                title="Pricing Table"
                subTitle="Our Pricing Plan"
            />
*/}
            <Testmonial
                className="testmonialArea pt100"
            />
{/*
            <NewsLetter
                className="newsLetterArea"
            />
*/}
            <FooterArea/>
        </Fragment>
    )
}
export default PracticePage