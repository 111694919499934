import React from "react";
import SectionTitle from '../Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import './style.scss'

const ServiceArea = ({ className, title, subTitle,services }) => {
    return (
        <div className={className}>
            <div className="container">
                <div className="row" style={{ justifyContent: 'space-around' }}>
                    <div className="col-12">
                        <SectionTitle
                            title={title}
                            subTitle={subTitle}
                        />
                    </div>
                    {services.map((service, index) => (
                        <div key={index} className="col-lg-4 col-md-6">
                            <div className="serviceWrap">
                                <div className="serviceIcon">
                                    {service.icon === 'bank' ? (
                                        <FontAwesomeIcon icon={faUniversity} style={{ height: '2.5rem', width: '2.5rem', color: '#c0b596' }} />
                                    ) : (
                                        <i className={`fi ${service.icon}`}></i>
                                    )}
                                </div>
                                <div className="serviceContent">
                                    <h3>{service.title}</h3>
                                    <p>{service.content}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>

    )
}
export default ServiceArea