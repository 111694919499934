import React from 'react'
import './style.scss'
const HeaderTop = props => {
    return (
        <div className={props.className}>
            <div className="container">
                <div className="headerTopMainWrapper">
                    <div className="row">
                        <div className="col-md-6 col-sm-12 col-12 col-lg-7">
                            <ul className="d-flex accountLoginArea">
                                <a href='https://goo.gl/maps/xUVRGEu6D3F9Wgr2A' target='_blank' rel="noopener noreferrer">
                                    <li>
                                        <i className="fa fa-map-marker" aria-hidden="true"></i>
                                        1651 South Milford Road - Suite 101, Highland, Michigan 48357
                                    </li>
                                </a>
                            </ul>
                        </div>
                        <div className="col-md-6 col-sm-12 col-12 col-lg-5">
                            <ul className="headerContact">
                                <li><i className="fa fa-phone"></i> <a href='tel:2487751002'>248-775-1002</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default HeaderTop