import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.scss';
// images
import testmonial from '../../images/testimonial/handshake.jpg';

const sliders = [
    {
        text: 'When I hired Vasili Sanios, he eased my mind and helped me understand what I was going through. I felt very taken care of and in good hands, He kept in contact and I genuinely had a huge relief knowing he was representing me.',
        title: 'Natalie W.',
        subTitle: 'Client'
    },
    {
        text: `Thank you so much for all your help. I walked into your office, you were the 14th lawyer I had seen. No one would take my case. I was so happy when you accepted it. You stuck with me all the way & I appreciate that. In the end we WON. I can't thank you enough for not only representing me but also representing my daughter. You have become more than just a lawyer but a friend. You're a blessing.`,
        title: 'Diana',
        subTitle: 'Client'
    },
    {
        text: 'For anyone who is facing the reality that a bankruptcy may be their fate you should really reach out to Attorney Sanios. I was completely embarrassed and overwhelmed with the thought of having to go through this. He was very patient with me and never made me feel bad about my circumstances. He answered all my questions and worked extremely hard and diligent on my case. His knowledge, professionalism and level of communication was impeccable. I would refer him to anyone who is looking to file bankruptcy.',
        title: 'E. M.',
        subTitle: 'Client'
    }
]
const settings = {
    dots: true,
    infinite: true,
    arrows: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
};
const Testmonial = ({ className }) => {
    return (
        <div className={className} style={{ paddingTop: '100px', paddingBottom: '100px' }}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-6">
                        <div className="testimonialImages">
                            <img src={testmonial} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-7 offset-lg-1 col-md-6">
                        <div className="testimonialContent">
                            <span>What People Say</span>
                            <h2>Client Testimonials</h2>
                            <Slider className="testmonialSlider" {...settings}>
                                {sliders.map((slider, i) => (
                                    <div key={i} className="slideItem">
                                        <p>{slider.text}</p>
                                        <div className="thumbWrap">
                                            {/*
                                            <div className="thumbImg">
                                                <img src={slider.images} alt="" />
                                            </div>
                                            */}
                                            <div className="imgContent">
                                                <h4>{slider.title}</h4>
                                                <span>{slider.subTitle}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testmonial